<template>
  <div class="-page-margin relative !mb-2 h-fit w-[calc(100%+var(--page-margin)*2)]">
    <div class="ml-16 flex h-[6.5rem] justify-end pb-[20px] pr-16">
      <img :src="logoImg" alt="logo" />
    </div>
    <div class="flex w-full flex-row justify-between">
      <div class="flex h-[8.5rem] max-w-[700px] flex-1 flex-col justify-between pt-[300px]">
        <div class="flex flex-row items-center text-sm font-bold text-white">
          <div class="flex h-[100px] w-full flex-row items-center">
            <div class="flex h-full w-[700px] items-center" :style="{ background: headerColorList[3] }">
              <div class="content-center pl-[35px] text-3xl">
                {{ translate(props.options?.title) }}
              </div>
            </div>
            <div class="h-full w-[20px]" :style="{ background: headerColorList[4] }"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { getLogoImg } from '@100-m/hauru/src/applications/builder/lib/header'
import useTranslations from '../composables/translations'

const props = defineProps(['data', 'options', 'context'])
const { translate } = useTranslations(props as any)

const logoImg = computed(() => getLogoImg(props.context))

const headerColorList = computed(() => {
  const headerPalette = props.context?.stylesheet?.palettes?.find(p => p.name === 'Landscape')
  if (!headerPalette) {
    return Array(5).fill('grey')
  }
  const headerColorList = props.context?.stylesheet?.palettes?.find(p => p.name === 'Landscape')?.colors || []
  // if (headerColorList.length < 5) headerColorList = headerColorList.append(Array(5).fill('grey')).slice(0, 5)
  return headerColorList
})
</script>
<script lang="ts">
import { BuilderOption } from '@100-m/hauru/src/applications/builder/composables/builderOptions'

const style: BuilderOption = {
  label: 'Style',
  autocomplete: ({ data_component }) => ['normal', 'light'],
  default: ({ data_component }) => 'normal',
  attrs: {
    required: true,
  },
}

export default {
  api: {
    style,
  },
}
</script>
