<template>
  <div class="-page-margin px-16">
    <div class="header flex h-fit w-full justify-between" style="border-bottom: 2px solid var(--primary)">
      <div class="title flex items-end p-2 text-4xl" style="padding-bottom: 20px">
        {{ capitalizeFirstLetter(translate('contents')) }}
      </div>
      <div class="" style="height: 80px; margin-bottom: 30px">
        <img :src="logoImg" alt="logo" style="height: 100%" width="auto" />
      </div>
    </div>

    <div class="content flex-col" style="padding: 80px" v-if="computeTableOfContents.length">
      <div class="flex justify-between py-3">
        <div class="col-1"></div>
        <div class="col-2 flex items-center justify-center text-lg" style="width: 100px">
          {{ capitalizeFirstLetter(translate('page')) }}
        </div>
      </div>
      <div class="flex justify-between gap-2 py-3" v-for="{ title, pageNumber } in computeTableOfContents">
        <div class="col-1 flex flex-wrap items-center justify-center text-lg">{{ translate(title) }}</div>
        <div class="col-2 flex items-center justify-center text-lg" style="width: 100px">{{ pageNumber || '' }}</div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { getLogoImg } from '@100-m/hauru/src/applications/builder/lib/header'
import useTranslations from '../composables/translations'
import { getTableOfContentByTargetComponentName } from '../lib/getTableOfContent'

const props = defineProps(['data', 'options', 'context'])
const { translate } = useTranslations(props as any)

const logoImg = computed(() => getLogoImg(props.context))

const computeTableOfContents = computed(() => {
  return getTableOfContentByTargetComponentName({ state: props.context.layout?.nodes || [] })
})
</script>
<script lang="ts">
import { BuilderOption } from '@100-m/hauru/src/applications/builder/composables/builderOptions'
import { capitalizeFirstLetter } from '../../client-edition/utils/reportFileHandlers'

const style: BuilderOption = {
  label: 'Style',
  autocomplete: ({ data_component }) => ['normal', 'light'],
  default: ({ data_component }) => 'normal',
  attrs: {
    required: true,
  },
}
export default {
  api: {
    style,
  },
}
</script>
